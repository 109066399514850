import { TZDateMini } from '@date-fns/tz';
import { addDays, subDays } from 'date-fns';
import { createAction } from '@shared/context/reducerUtils';
import type { OccupantsOccupant } from 'restaurantAdmin/reservations/occupants/apiHelpers';

enum OccupantActionType {
  ResetView = 'ResetView',
  SelectNextOccupant = 'SelectNextOccupant',
  SelectPreviousOccupant = 'SelectPreviousOccupant',
  SelectOccupant = 'SelectOccupant',
  SelectToday = 'SelectToday',
  SelectPreviousDay = 'SelectPreviousDay',
  SelectNextDay = 'SelectNextDay',
  SelectDate = 'SelectDate',
  UpdateOccupants = 'UpdateOccupants',
}

export const resetView = () => createAction(OccupantActionType.ResetView, null);
export const selectNextOccupant = () =>
  createAction(OccupantActionType.SelectNextOccupant, null);
export const selectPreviousOccupant = () =>
  createAction(OccupantActionType.SelectPreviousOccupant, null);
export const selectOccupant = (occupantId: string) =>
  createAction(OccupantActionType.SelectOccupant, { occupantId });
export const selectToday = (timezone: string) =>
  createAction(OccupantActionType.SelectToday, { timezone });
export const selectPreviousDay = () =>
  createAction(OccupantActionType.SelectPreviousDay, null);
export const selectNextDay = () =>
  createAction(OccupantActionType.SelectNextDay, null);
export const selectDate = (date: Date) =>
  createAction(OccupantActionType.SelectDate, { date });
export const updateOccupants = (occupants: OccupantsOccupant[]) =>
  createAction(OccupantActionType.UpdateOccupants, { occupants });

export interface OccupantState {
  reservationDate: Date;
  selectedIndex: number;
  occupants: OccupantsOccupant[];
}

export const occupantReducer = (
  state: OccupantState,
  action:
    | ReturnType<typeof resetView>
    | ReturnType<typeof selectNextOccupant>
    | ReturnType<typeof selectPreviousOccupant>
    | ReturnType<typeof selectOccupant>
    | ReturnType<typeof selectToday>
    | ReturnType<typeof selectPreviousDay>
    | ReturnType<typeof selectNextDay>
    | ReturnType<typeof selectDate>
    | ReturnType<typeof updateOccupants>,
): OccupantState => {
  switch (action.type) {
    case OccupantActionType.ResetView:
      return {
        ...state,
        selectedIndex: -1,
      };
    case OccupantActionType.SelectNextOccupant: {
      const lastOccupant = state.occupants.length <= state.selectedIndex + 1;
      return lastOccupant
        ? state
        : {
            ...state,
            selectedIndex: state.selectedIndex + 1,
          };
    }
    case OccupantActionType.SelectPreviousOccupant: {
      const firstOccupant = state.selectedIndex === 0;
      return firstOccupant
        ? state
        : {
            ...state,
            selectedIndex: state.selectedIndex - 1,
          };
    }
    case OccupantActionType.SelectOccupant: {
      const indexOfOccupant = state.occupants.findIndex(
        (occupant) => occupant.id === action.payload.occupantId,
      );
      return {
        ...state,
        selectedIndex: indexOfOccupant,
      };
    }
    case OccupantActionType.SelectToday:
      return {
        ...state,
        selectedIndex: -1,
        reservationDate: TZDateMini.tz(action.payload.timezone),
      };
    case OccupantActionType.SelectPreviousDay:
      return {
        ...state,
        selectedIndex: -1,
        reservationDate: subDays(state.reservationDate, 1),
      };
    case OccupantActionType.SelectNextDay:
      return {
        ...state,
        selectedIndex: -1,
        reservationDate: addDays(state.reservationDate, 1),
      };
    case OccupantActionType.SelectDate:
      return {
        ...state,
        selectedIndex: -1,
        reservationDate: action.payload.date,
      };
    case OccupantActionType.UpdateOccupants:
      return {
        ...state,
        occupants: action.payload.occupants,
      };
    default:
      return state;
  }
};
