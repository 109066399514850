import { PageContent } from '../../layout/PageContent';
import { PageHeader } from '../../layout/PageHeader';
import { isReservation, isWalkIn } from './apiHelpers';
import styles from './OccupantsPage.scss';
import { OpenCloseRestaurantButton } from './OpenCloseRestaurantButton';
import { OccupantsPageHeader } from './pageHeader/OccupantsPageHeader';
import { OccupantList } from './sidePanel/OccupantList';
import { OccupantSheetWrapper } from './sidePanel/OccupantSheetWrapper';
import { ReservationGuestSheet } from './sidePanel/ReservationGuestSheet';
import { WalkInSheet } from './sidePanel/WalkInSheet';
import { useOccupantContext } from './state/OccupantContext';

export const OccupantsPage = () => {
  const {
    clearOccupant,
    handleOnClickNextOccupant,
    handleOnClickPreviousOccupant,
    occupantPositionValues,
    selectedOccupant,
  } = useOccupantContext();

  const renderContent = () => {
    if (selectedOccupant) {
      return (
        <OccupantSheetWrapper
          onClickNextOccupant={handleOnClickNextOccupant}
          onClickPreviousOccupant={handleOnClickPreviousOccupant}
          onClose={() => {
            clearOccupant();
          }}
          occupantPositionValues={occupantPositionValues}
        >
          {isReservation(selectedOccupant) && (
            <ReservationGuestSheet
              guest={selectedOccupant.guest}
              reservation={selectedOccupant}
            />
          )}
          {isWalkIn(selectedOccupant) && (
            <WalkInSheet walkIn={selectedOccupant} />
          )}
        </OccupantSheetWrapper>
      );
    }

    return <OccupantList />;
  };

  return (
    <>
      <PageHeader category="Reservations" title="Occupants">
        <OpenCloseRestaurantButton />
      </PageHeader>
      <PageContent className={styles.pageContent}>
        <OccupantsPageHeader />
        <div className={styles.occupants}>{renderContent()}</div>
      </PageContent>
    </>
  );
};
