import type { ReactNode } from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import type { ApiResponse } from '@shared/types/apiHelpers';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import { useError } from 'restaurantAdmin/errors/useError';
import type { RestaurantsMetadata } from '../auth/apiHelpers';
import * as apiHelpers from '../auth/apiHelpers';
import * as restaurantApiHelpers from '../restaurants/apiHelpers';
import { type RestaurantMetadata } from '../restaurants/apiHelpers';

const LAST_VIEWED_RESTAURANT_ID_KEY =
  'peakRestaurantAdminLastViewedRestaurantId';

export interface AuthContextState {
  adminName?: string;
  isAuthenticated: boolean;
  isLoading: boolean;
  loginAndSetRestaurants: (
    email: string,
    password: string,
  ) => Promise<ApiResponse<RestaurantsMetadata>>;
  refreshRestaurants: () => void;
  restaurants: RestaurantMetadata[];
  selectedRestaurant?: RestaurantMetadata;
  selectedRestaurantId?: string;
  updateSelectedRestaurantId: (id: string) => void;
  logout: (onSuccess: () => void) => void;
}

export const AuthContext = createContext<AuthContextState | null>(null);
AuthContext.displayName = 'Auth';

export const useAuth = () => useDefinedContext(AuthContext);

interface ObjectWithId {
  id: string;
}

const toMapWithIdAsKey = <T extends ObjectWithId>(
  arr: T[],
): Record<string, T> =>
  arr.reduce<Record<string, T>>((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [adminName, setAdminName] = useState<string>('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRestaurantId, setSelectedRestaurantId] = useState<string>();
  const [restaurants, setRestaurants] = useState<
    Record<string, RestaurantMetadata>
  >({});
  const setError = useError();

  const defaultSelectedRestaurantId = (
    adminsRestaurants: RestaurantMetadata[] = [],
  ) => {
    const lastViewedRestaurantId =
      localStorage.getItem(LAST_VIEWED_RESTAURANT_ID_KEY) ?? undefined;

    const hasLastViewedRestaurantId = adminsRestaurants.some(
      (r) => r.id === lastViewedRestaurantId,
    );
    if (hasLastViewedRestaurantId) {
      setSelectedRestaurantId(lastViewedRestaurantId);
    } else {
      const firstRestaurantId = adminsRestaurants[0]?.id;
      setSelectedRestaurantId(firstRestaurantId);
      localStorage.setItem(LAST_VIEWED_RESTAURANT_ID_KEY, firstRestaurantId);
    }
  };

  const updateSelectedRestaurantId = (id: string): void => {
    setSelectedRestaurantId(id);
    localStorage.setItem(LAST_VIEWED_RESTAURANT_ID_KEY, id);
  };

  useEffect(() => {
    const func = async () => {
      try {
        const response = await apiHelpers.checkAuth();
        if ('restaurants' in response) {
          const restaurantMap = toMapWithIdAsKey(response.restaurants);
          setRestaurants(restaurantMap);
          defaultSelectedRestaurantId(response.restaurants);
          setIsAuthenticated(response.isAuthenticated);
          setAdminName(response.fullName || '');
        }
        setIsLoading(false);
      } catch (e) {
        setError(e);
      }
    };
    void func();
  }, []);

  const loginAndSetRestaurants = async (
    email: string,
    password: string,
  ): Promise<ApiResponse<RestaurantsMetadata>> => {
    const response = await apiHelpers.login(email, password);
    if (isSuccessResponse(response)) {
      const restaurantMap = toMapWithIdAsKey(response.restaurants);
      setRestaurants(restaurantMap);
      defaultSelectedRestaurantId(response.restaurants);
      setIsAuthenticated(true);
      setAdminName(response.fullName || '');
    }
    return response;
  };

  const logout = async (onSuccess: () => void) => {
    const response = await apiHelpers.signOut();

    if (response.ok) {
      localStorage.clear();
      setIsAuthenticated(false);
      onSuccess();
    }
  };

  const refreshRestaurants = () => {
    setIsLoading(true);
    void (async () => {
      try {
        const response = await restaurantApiHelpers.getRestaurants();
        const restaurantMap = toMapWithIdAsKey(response);
        setRestaurants(restaurantMap);
        defaultSelectedRestaurantId(response);
      } finally {
        setIsLoading(false);
      }
    })();
  };

  // derived state
  const selectedRestaurant = selectedRestaurantId
    ? restaurants[selectedRestaurantId]
    : undefined;
  const restaurantValues = Object.values(restaurants);

  const value = useMemo<AuthContextState>(
    () => ({
      adminName,
      isAuthenticated,
      isLoading,
      loginAndSetRestaurants,
      refreshRestaurants,
      restaurants: restaurantValues,
      selectedRestaurant,
      selectedRestaurantId,
      updateSelectedRestaurantId,
      logout,
    }),
    [
      adminName,
      isLoading,
      isAuthenticated,
      refreshRestaurants,
      selectedRestaurantId,
      selectedRestaurant,
      restaurants,
    ],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
