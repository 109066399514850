import { TZDateMini } from '@date-fns/tz';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import type { FloorPlanData } from '@shared/types/floorPlans';
import { todayInTimezone } from '@shared/utils/date';
import { dateToISOTime, ISOTimeFloorToQuarterHour } from '@utils/time';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { useAdminFloorPlan } from '../../hooks/useAdminFloorPlan';
import {
  OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH,
} from '../../paths';
import type { Listing } from './apiHelpers';
import { useListings } from './useListings';
import {
  getListingsForWeekByDate,
  isOverlappingAtTime,
  listingIsOnDateWithRollover,
} from './utils/listingUtils';

export interface ListingsContextState {
  clearSelectedListing: () => void;
  floorPlan: FloorPlanData;
  isLoading: boolean;
  isOnDraft: boolean;
  isOnFloorPlan: boolean;
  /**
   * This will contain all published listings by default, but will contain
   * all draft listings when the Draft tab is selected in the Listings page.
   */
  allListings: Listing[];
  /** Listings in scope for the entire page - filtered by date/time selectors */
  pageListings: Listing[];
  /** Listings in scope for the list view - filtered by selection on the calendar or the floor plan */
  listListings: Listing[];
  refreshFloorPlan: () => void;
  refreshListings: () => void;
  selectedDate: string;
  selectedFloorPlanTableListingIds: string[];
  selectedFloorPlanTime: string | null;
  selectedListing: Listing | undefined;
  setSelectedDate: (date: string) => void;
  setSelectedFloorPlanTableListingIds: Dispatch<SetStateAction<string[]>>;
  setSelectedFloorPlanTime: (time: string | null) => void;
  setSelectedListingId: (listingId: string) => void;
  selectedCalendarCellListingIds: string[];
  setSelectedCalendarCellListingIds: Dispatch<SetStateAction<string[]>>;
}

export const ListingsContext = createContext<ListingsContextState | null>(null);
ListingsContext.displayName = 'ListingsContext';

export const useListingsContext = (): ListingsContextState =>
  useDefinedContext(ListingsContext);

export const ListingsContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { pathname } = useLocation();
  const isOnFloorPlan =
    pathname === OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH ||
    pathname === OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_PATH;

  const {
    isOnDraft,
    isLoading: isListingsLoading,
    listings: allListings,
    refreshListings,
  } = useListings();
  const { timezone } = useRestaurant();
  const {
    isLoading: isFloorPlanLoading,
    floorPlan,
    fetchFloorPlan,
  } = useAdminFloorPlan(false);

  const [selectedDate, setSelectedDate] = useState(() =>
    todayInTimezone(timezone),
  );
  const [selectedFloorPlanTime, setSelectedFloorPlanTime] = useState<
    string | null
  >(() => ISOTimeFloorToQuarterHour(dateToISOTime(TZDateMini.tz(timezone))));
  const [
    selectedFloorPlanTableListingIds,
    setSelectedFloorPlanTableListingIds,
  ] = useState<string[]>([]);
  const [selectedCalendarCellListingIds, setSelectedCalendarCellListingIds] =
    useState<string[]>([]);
  const [selectedListingId, setSelectedListingId] = useState<string>();

  const selectedListing = allListings.find(
    (listing) => listing.id === selectedListingId,
  );
  const clearSelectedListing = () => setSelectedListingId(undefined);

  const getPageListings = () => {
    if (isOnFloorPlan) {
      if (selectedFloorPlanTime) {
        return allListings.filter(
          isOverlappingAtTime(selectedFloorPlanTime, selectedDate),
        );
      }
      return allListings.filter((l) =>
        listingIsOnDateWithRollover(l, selectedDate),
      );
    }
    return getListingsForWeekByDate(allListings, selectedDate);
  };
  const pageListings = getPageListings();

  const getListListings = () => {
    if (isOnFloorPlan) {
      if (selectedFloorPlanTableListingIds.length) {
        return pageListings.filter((listing) =>
          selectedFloorPlanTableListingIds.includes(listing.id),
        );
      }
    } else if (selectedCalendarCellListingIds.length) {
      return pageListings.filter((listing) =>
        selectedCalendarCellListingIds.includes(listing.id),
      );
    }
    return pageListings;
  };
  const listListings = getListListings();

  const handleSetSelectedDate = (date: string) => {
    setSelectedFloorPlanTableListingIds([]);
    setSelectedDate(date);
  };

  const handleSetSelectedFloorPlanTime = (time: string | null) => {
    setSelectedFloorPlanTableListingIds([]);
    setSelectedFloorPlanTime(time);
  };

  const value = useMemo(
    (): ListingsContextState => ({
      clearSelectedListing,
      floorPlan: floorPlan!,
      isLoading: isListingsLoading || isFloorPlanLoading,
      isOnDraft,
      isOnFloorPlan,
      allListings,
      pageListings,
      listListings,
      refreshFloorPlan: fetchFloorPlan,
      refreshListings,
      selectedDate,
      selectedFloorPlanTableListingIds,
      selectedFloorPlanTime,
      selectedListing,
      setSelectedDate: handleSetSelectedDate,
      setSelectedFloorPlanTableListingIds,
      setSelectedFloorPlanTime: handleSetSelectedFloorPlanTime,
      setSelectedListingId,
      selectedCalendarCellListingIds,
      setSelectedCalendarCellListingIds,
    }),
    [
      floorPlan,
      isFloorPlanLoading,
      isListingsLoading,
      isOnDraft,
      isOnFloorPlan,
      allListings,
      pageListings,
      listListings,
      selectedDate,
      selectedFloorPlanTableListingIds,
      selectedFloorPlanTime,
      selectedListing,
      selectedCalendarCellListingIds,
      setSelectedCalendarCellListingIds,
    ],
  );

  return (
    <ListingsContext.Provider value={value}>
      {children}
    </ListingsContext.Provider>
  );
};
