import cx from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { Button, ButtonVariants } from '@components/button/Button';
import { Card } from '@components/card/Card';
import { ControlledFormInput } from '@components/formInputs/ControlledFormInput';
import { LayoutVariant } from '@components/formInputs/sharedTypes';
import { GuestCountPicker } from '@components/guestCountPicker/GuestCountPicker';
import { Icon } from '@components/icon/Icon';
import { IconButton } from '@components/iconButton/IconButton';
import { errorToast } from '@components/toasts/Toasts';
import { DESKTOP1280 } from '@shared/styles/breakpoints';
import {
  getErrorResponseMessage,
  isSuccessResponse,
} from '@shared/types/apiHelpers';
import { HUMAN_READABLE_ICON_NAMES } from '@shared/types/floorPlans';
import {
  ModalType,
  useModalContext,
} from 'restaurantAdmin/context/ModalContext';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import type { HostFloorPlanTable } from 'restaurantAdmin/floorPlans/apiHelpers';
import typography from '~styles/typography.scss';
import {
  getWalkInAvailability,
  seatWalkIn,
  type WalkInAvailability,
} from '../../apiHelpers';
import { useReservationServiceContext } from '../state/ReservationServiceContext';
import styles from './WalkInForm.scss';
import { WalkInSheetWarning } from './WalkInSheetWarning';

const DEFAULT_GUEST_COUNT = 2;

export interface WalkInFormProps {
  className?: string;
  selectedTable: HostFloorPlanTable;
  onClose: () => void;
  handleWalkInSeated: () => void;
}

interface WalkInFormData {
  guestCount: number;
  firstName: string;
  lastName: string;
}

export const WalkInForm = ({
  className,
  selectedTable,
  onClose,
  handleWalkInSeated,
}: WalkInFormProps) => {
  const { openModal } = useModalContext();
  const { refreshOccupants, refreshFloorPlan } = useReservationServiceContext();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    getValues,
  } = useForm<WalkInFormData>({
    defaultValues: {
      guestCount: DEFAULT_GUEST_COUNT,
    },
  });
  const { id: restaurantId, maxReservationGuests } = useRestaurant();
  const layoutVariant = useMediaQuery({ minWidth: DESKTOP1280 })
    ? LayoutVariant.Horizontal
    : LayoutVariant.Vertical;

  const createSeatWalkIn = async (
    data: WalkInFormData,
    availability: WalkInAvailability,
  ): Promise<void> => {
    const response = await seatWalkIn({
      restaurantId,
      floorPlanTableId: selectedTable.id,
      guestCount: data.guestCount,
      ...(data.firstName && { firstName: data.firstName }),
      ...(data.lastName && { lastName: data.lastName }),
      turnTime: availability.turnTime,
      hasAvailability: availability.hasAvailability,
    });

    if (response.ok) {
      handleWalkInSeated();
    } else {
      const errorMessage = await getErrorResponseMessage(response);
      errorToast({ message: errorMessage });
    }
    refreshFloorPlan();
    refreshOccupants();
  };

  const submitWalkIn = async (data: WalkInFormData) => {
    const response = await getWalkInAvailability({
      floorPlanTableId: selectedTable.id,
      guestCount: data.guestCount,
      restaurantId,
    });

    if (isSuccessResponse(response)) {
      if (!response.hasAvailability) {
        openModal(ModalType.WalkInSeatingConflict, {
          handleConfirm: () => {
            void createSeatWalkIn(getValues(), response);
          },
        });
        return;
      }

      if (response.turnTime !== null) {
        openModal(ModalType.WalkInTurnTimeInformation, {
          handleConfirm: () => {
            void createSeatWalkIn(getValues(), response);
          },
          turnTime: response.turnTime,
        });
        return;
      }

      await createSeatWalkIn(data, response);
    } else {
      onClose();
      errorToast({
        message: 'Failed to confirm seat availability. Please try again.',
      });
    }
  };

  return (
    <Card bordered className={cx(styles.container, className)}>
      <header>
        <p className={cx(typography.h4)}>Seat Walk-In</p>
        <IconButton
          ariaLabel="close sheet"
          onClick={onClose}
          iconName="close"
        />
      </header>
      <WalkInSheetWarning floorPlanTableId={selectedTable.id} />
      <Card className={cx(typography.t1, styles.tableCard)}>
        <Icon
          name={selectedTable.iconName}
          ariaLabel={HUMAN_READABLE_ICON_NAMES[selectedTable.iconName]}
        />
        {selectedTable.name}
      </Card>
      <form
        aria-label="Seat Walk-in Form"
        onSubmit={handleSubmit(submitWalkIn)}
      >
        <Controller
          control={control}
          name="guestCount"
          render={({ field: { onChange, value } }) => (
            <GuestCountPicker
              guestCount={value}
              max={maxReservationGuests}
              onChange={onChange}
            />
          )}
        />
        <ControlledFormInput
          className={styles.nameInput}
          control={control}
          label="First Name"
          name="firstName"
          placeholder="Optional"
          type="text"
          variant={layoutVariant}
        />
        <ControlledFormInput
          className={styles.nameInput}
          control={control}
          label="Last Name"
          name="lastName"
          placeholder="Optional"
          type="text"
          variant={layoutVariant}
        />
        <div className={styles.callToActionSection}>
          <Button
            label="Seat Walk-in"
            type="submit"
            variant={ButtonVariants.Primary}
            isDisabled={isSubmitting}
          />
          <Button
            label="Cancel"
            onClick={onClose}
            variant={ButtonVariants.Tertiary}
          />
        </div>
      </form>
    </Card>
  );
};
