import { max } from 'lodash-es';
import type { BaseSyntheticEvent, FormEventHandler, ReactNode } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import type { Control } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { parseTimeValues } from '@components/formInputs/timeValuesUtils';
import { successToast } from '@components/toasts/Toasts';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import { CENTS_IN_DOLLAR } from '@utils/currency';
import { todayInTimezone } from '@utils/date';
import { useRestaurant } from '../../../context/useRestaurant';
import {
  OPERATIONS_LISTINGS_CALENDAR_DRAFT_PATH,
  OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_PATH,
  OPERATIONS_LISTINGS_CREATE_TIME_AND_PRICE_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH,
} from '../../../paths';
import type { CreateListingPayload } from '../apiHelpers';
import { createListing } from '../apiHelpers';
import { useListingsContext } from '../ListingsContext';
import type {
  ListingDetailsFormData,
  ListingTimeAndPriceFormData,
} from '../types';
import {
  listingDetailsFormDataFromListing,
  type ListingLocationState,
  listingTimeAndPriceFormDataFromListing,
} from '../utils/listingUtils';

interface ModalState {
  currentModal: 'error' | 'warning' | null;
  isSavingDraft?: boolean;
  message?: string;
}

export interface CreateListingContextState {
  detailsControl: Control<ListingDetailsFormData>;
  timeAndPriceControl: Control<ListingTimeAndPriceFormData>;
  handleOnClickAddTimeAndPrice: FormEventHandler<HTMLFormElement>;
  handleOnSubmit: FormEventHandler<HTMLFormElement>;
  handleOnConfirmSubmit: () => void;
  clearModalState: () => void;
  modalState: ModalState;
}

export const CreateListingContext = createContext<CreateListingContextState>(
  {} as CreateListingContextState,
);
CreateListingContext.displayName = 'CreateListingContext';

export const useCreateListingContext = (): CreateListingContextState =>
  useContext(CreateListingContext);

const buildCreateListingPayload = (
  timeAndPriceFormValues: ListingTimeAndPriceFormData,
  detailsFormValues: ListingDetailsFormData,
  ignoreWarnings: boolean,
): CreateListingPayload => ({
  endDate: timeAndPriceFormValues.endDate || null,
  endTime: parseTimeValues(timeAndPriceFormValues.endTime),
  highlightedFloorPlanTableIds: detailsFormValues.highlightedTables.map(
    (table) => table.id,
  ),
  iconName: detailsFormValues.iconName,
  interval: detailsFormValues.interval,
  inventoryCount: Number(detailsFormValues.inventoryCount),
  isCommunal: detailsFormValues.isCommunal,
  maximumGuests: Number(detailsFormValues.maximumGuests),
  minimumGuests: Number(detailsFormValues.minimumGuests),
  name: detailsFormValues.name,
  price: Number(timeAndPriceFormValues.price) * CENTS_IN_DOLLAR,
  repeat: timeAndPriceFormValues.repeat,
  startDate: timeAndPriceFormValues.startDate,
  startTime: parseTimeValues(timeAndPriceFormValues.startTime),
  publicName: detailsFormValues.publicName,
  turnTime: detailsFormValues.turnTime,
  ignoreWarnings,
});

export const CreateListingContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const location = useLocation();
  const { isFromFloorPlan = false, duplicateListingId } =
    (location.state as ListingLocationState) || {};
  const navigate = useNavigate();
  const { id: restaurantId, timezone } = useRestaurant();
  const {
    allListings,
    refreshFloorPlan,
    refreshListings,
    setSelectedDate,
    setSelectedListingId,
  } = useListingsContext();

  const listingToDuplicate = allListings.find(
    (listing) => listing.id === duplicateListingId,
  );

  const detailsForm = useForm<ListingDetailsFormData>({
    defaultValues: listingToDuplicate
      ? {
          ...listingDetailsFormDataFromListing(listingToDuplicate, null),
          name: `${listingToDuplicate.name} - Copy`,
        }
      : { highlightedTables: [], isCommunal: false },
  });
  const timeAndPriceForm = useForm<ListingTimeAndPriceFormData>({
    defaultValues: listingToDuplicate
      ? listingTimeAndPriceFormDataFromListing(listingToDuplicate)
      : { endDate: '', repeat: [], startTime: [], endTime: [] },
  });
  const [modalState, setModalState] = useState<ModalState>({} as ModalState);

  const attemptListingCreate = async ({
    isSavingDraft,
    ignoreWarnings,
  }: {
    isSavingDraft: boolean;
    ignoreWarnings: boolean;
  }) => {
    const detailsFormValues = detailsForm.getValues();
    const timeAndPriceFormValues = timeAndPriceForm.getValues();

    const response = await createListing(
      restaurantId,
      buildCreateListingPayload(
        timeAndPriceFormValues,
        detailsFormValues,
        ignoreWarnings,
      ),
      isSavingDraft,
    );

    const getPath = () => {
      if (isSavingDraft) {
        if (isFromFloorPlan) return OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_PATH;
        return OPERATIONS_LISTINGS_CALENDAR_DRAFT_PATH;
      }
      if (isFromFloorPlan) return OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH;
      return OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_PATH;
    };

    if (isSuccessResponse(response)) {
      if ('warnings' in response) {
        setModalState({
          currentModal: 'warning',
          isSavingDraft,
          message: response.warnings[0],
        });
      } else {
        refreshListings();
        refreshFloorPlan();
        successToast({
          message: `Listing successfully ${isSavingDraft ? 'saved as draft' : 'published'}`,
        });
        setSelectedDate(
          max([timeAndPriceFormValues.startDate, todayInTimezone(timezone)])!,
        );
        setSelectedListingId(response.createdListingId);
        navigate(getPath());
      }
    } else {
      setModalState({
        currentModal: 'error',
        isSavingDraft,
        message: response.message,
      });
    }
  };

  const handleOnSubmit = async (
    _: any,
    e: BaseSyntheticEvent<any> | undefined,
  ) => {
    await attemptListingCreate({
      isSavingDraft: e?.nativeEvent?.submitter?.name === 'draft',
      ignoreWarnings: false,
    });
  };

  const handleOnConfirmSubmit = async () => {
    await attemptListingCreate({
      isSavingDraft: modalState.isSavingDraft!,
      ignoreWarnings: true,
    });
  };

  const handleOnClickAddTimeAndPrice = () => {
    navigate(OPERATIONS_LISTINGS_CREATE_TIME_AND_PRICE_PATH, {
      state: {
        isFromFloorPlan,
        duplicateListingId,
      } as ListingLocationState,
    });
  };

  const clearModalState = () => {
    setModalState({ currentModal: null });
  };

  const value = useMemo<CreateListingContextState>(
    () => ({
      detailsControl: detailsForm.control,
      timeAndPriceControl: timeAndPriceForm.control,
      handleOnClickAddTimeAndPrice: detailsForm.handleSubmit(
        handleOnClickAddTimeAndPrice,
      ),
      handleOnSubmit: timeAndPriceForm.handleSubmit(handleOnSubmit),
      handleOnConfirmSubmit,
      modalState,
      clearModalState,
    }),
    [detailsForm.control, timeAndPriceForm.control, modalState],
  );

  return (
    <CreateListingContext.Provider value={value}>
      {children}
    </CreateListingContext.Provider>
  );
};
