import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import {
  OPERATIONS_LISTINGS_CALENDAR_DRAFT_PATH,
  OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH,
} from 'restaurantAdmin/paths';
import layoutStyles from '../../../layout/Layout.scss';
import { type Listing } from '../apiHelpers';
import { useListingsContext } from '../ListingsContext';
import type { ListingDetailsFormData } from '../types';
import {
  listingDetailsFormDataFromListing,
  type ListingLocationState,
} from '../utils/listingUtils';
import { useEditListingContext } from './EditListingContext';
import { EditListingDetailsPageBody } from './EditListingDetailsPageBody';
import { EditListingFloorPlanModalContainer } from './EditListingFloorPlanModalContainer';
import { EditListingPageHeader } from './EditListingPageHeader';

export const EditListingFloorPlanForm = ({
  existingListing,
}: {
  existingListing: Listing;
}) => {
  const { floorPlan } = useListingsContext();
  const location = useLocation();
  const isFromFloorPlan =
    (location.state as ListingLocationState)?.isFromFloorPlan || false;
  const { processEditSubmission } = useEditListingContext();

  const { control, handleSubmit, getValues } = useForm<ListingDetailsFormData>({
    defaultValues: listingDetailsFormDataFromListing(
      existingListing,
      floorPlan,
    ),
  });

  const getRedirectPath = () => {
    if (existingListing.status === 'draft') {
      if (isFromFloorPlan) return OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_PATH;
      return OPERATIONS_LISTINGS_CALENDAR_DRAFT_PATH;
    }

    if (isFromFloorPlan) return OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH;
    return OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_PATH;
  };

  const buildListingFromForm = (
    detailsFormValues: ListingDetailsFormData,
  ): Listing => ({
    ...existingListing,
    iconName: detailsFormValues.iconName,
    interval: detailsFormValues.interval,
    inventoryCount: Number(detailsFormValues.inventoryCount),
    maximumGuests: Number(detailsFormValues.maximumGuests),
    minimumGuests: Number(detailsFormValues.minimumGuests),
    name: detailsFormValues.name,
    publicName: detailsFormValues.publicName,
    turnTime: detailsFormValues.turnTime,
  });

  const submit = (ignoreWarnings: boolean) => {
    const detailsFormValues = getValues();
    const highlightedFloorPlanTableIds =
      detailsFormValues.highlightedTables.map((table) => table.id);
    const updatedListing = buildListingFromForm(detailsFormValues);
    void processEditSubmission(
      highlightedFloorPlanTableIds,
      ignoreWarnings,
      updatedListing,
    );
  };
  const handleOnSubmit = () => submit(false);
  const handleOnConfirmSubmit = () => submit(true);

  return (
    <>
      <form
        className={layoutStyles.pageWrapper}
        onSubmit={handleSubmit(handleOnSubmit)}
        noValidate
      >
        <EditListingPageHeader cancelPath={getRedirectPath()} />
        <EditListingDetailsPageBody
          isEditing
          control={control}
          floorPlan={floorPlan}
        />
      </form>
      <EditListingFloorPlanModalContainer
        handleOnConfirmSubmit={handleOnConfirmSubmit}
      />
    </>
  );
};
