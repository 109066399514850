import type { ApiResponse } from '@shared/types/apiHelpers';
import type { ReservationStatus } from 'restaurantAdmin/reservations/apiHelpers';
import { type GuestTag } from 'restaurantAdmin/restaurants/apiHelpers';
import { api } from '../api';
import DeprecatedAPI from '../api/apiClient';

export interface CreatedGuestNote {
  id: string;
}

export interface GuestNoteResponse {
  data: GuestNote | null;
}

export interface GuestNote {
  id: string;
  note: string;
  updatedBy: string | null;
}

interface TransactionDetails {
  createdBy: {
    fullName?: string;
  };
  fee: number;
  price: number;
  receiptNumber: string;
  refundAmount: number | null;
  tax: number;
}

export interface GuestReservation {
  date: string;
  guestCount: number;
  id: string;
  publicName: string;
  seatedTableName: string | null;
  status: ReservationStatus;
  time: string;
  transactionDetails: TransactionDetails;
}

export interface GuestsApiGuest {
  email: string;
  firstName: string;
  id: string;
  isVip: boolean;
  lastName: string;
  phone: string;
}

export const createGuestNotes = async ({
  restaurantId,
  guestId,
  note,
}: {
  restaurantId: string;
  guestId: string;
  note: string;
}): Promise<CreatedGuestNote> =>
  api.post(`/restaurants/${restaurantId}/guests/${guestId}/guest-notes`, {
    note,
  });

export const getGuests = async ({
  restaurantId,
  limit,
  offset,
  search,
  vipOnly = false,
}: {
  restaurantId: string;
  limit: number;
  offset: number;
  search: string;
  vipOnly: boolean;
}): Promise<ApiResponse<GuestsApiGuest[]>> => {
  const url =
    search === ''
      ? `/restaurants/${restaurantId}/guests?limit=${limit}&offset=${offset}&vipOnly=${vipOnly}`
      : `/restaurants/${restaurantId}/guests?limit=${limit}&offset=${offset}&vipOnly=${vipOnly}&search=${search.replace(
          /\s/g,
          '',
        )}`;
  const response = await DeprecatedAPI.get(url);

  return response.json();
};

export const getGuestNotes = async (
  restaurantId: string,
  guestId: string,
): Promise<GuestNoteResponse> =>
  api.get(`/restaurants/${restaurantId}/guests/${guestId}/guest-notes`);

export const getGuestTagsForGuest = async (
  restaurantId: string,
  guestId: string,
): Promise<ApiResponse<GuestTag[]>> => {
  const response = await DeprecatedAPI.get(
    `/restaurants/${restaurantId}/guests/${guestId}/tags`,
  );

  return response.json();
};

export const getReservationsForGuest = async (
  restaurantId: string,
  guestId: string,
): Promise<ApiResponse<GuestReservation[]>> => {
  const response = await DeprecatedAPI.get(
    `/restaurants/${restaurantId}/guests/${guestId}/reservations`,
  );

  return response.json();
};

export const updateGuestNotes = ({
  id,
  restaurantId,
  guestId,
  note,
}: {
  id: string;
  restaurantId: string;
  guestId: string;
  note: string;
}): Promise<void> =>
  api.put(`/restaurants/${restaurantId}/guests/${guestId}/guest-notes/${id}`, {
    note,
  });

export const updateGuestTags = async ({
  restaurantId,
  guestId,
  tags,
}: {
  restaurantId: string;
  guestId: string;
  tags: { id: string | null; title: string }[];
}): Promise<void> =>
  api.put(`/restaurants/${restaurantId}/guests/${guestId}/tags`, {
    tags,
  });
