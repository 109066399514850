import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { parseTimeValues } from '@components/formInputs/timeValuesUtils';
import layoutStyles from '../../../layout/Layout.scss';
import {
  OPERATIONS_LISTINGS_CALENDAR_DRAFT_PATH,
  OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH,
} from '../../../paths';
import type { Listing } from '../apiHelpers';
import { useListingsContext } from '../ListingsContext';
import type {
  ListingDetailsFormData,
  ListingTimeAndPriceFormData,
} from '../types';
import {
  type ListingLocationState,
  listingTimeAndPriceFormDataFromListing,
} from '../utils/listingUtils';
import { useEditListingContext } from './EditListingContext';
import { EditListingFloorPlanModalContainer } from './EditListingFloorPlanModalContainer';
import { EditListingPageHeader } from './EditListingPageHeader';
import { EditListingTimeAndPricePageBody } from './EditListingTimeAndPricePageBody';

export const EditListingReservableDaysForm = ({
  existingListing,
}: {
  existingListing: Listing;
}) => {
  const { floorPlan } = useListingsContext();
  const { processEditSubmission } = useEditListingContext();

  const location = useLocation();
  const isFromFloorPlan =
    (location.state as ListingLocationState)?.isFromFloorPlan || false;

  const generateHighlightedTableIds = () => {
    const highlightedTables = floorPlan?.floorPlanTables.filter(
      (floorPlanTable) =>
        floorPlanTable.listings.some(
          (floorPlanListing) => floorPlanListing.id === existingListing.id,
        ),
    );
    return highlightedTables?.map((table) => table.id) || [];
  };

  const { control: detailsControl } = useForm<ListingDetailsFormData>({
    defaultValues: {
      turnTime: existingListing.turnTime,
    },
  });

  const {
    control: timeAndPriceControl,
    handleSubmit,
    getValues,
  } = useForm<ListingTimeAndPriceFormData>({
    defaultValues: listingTimeAndPriceFormDataFromListing(existingListing),
  });

  const processTimeAndPriceListingEdits = (): Listing => {
    const listingTimeAndPriceFormData = getValues();
    return {
      ...existingListing,
      ...listingTimeAndPriceFormData,
      endTime: parseTimeValues(listingTimeAndPriceFormData.endTime),
      price: parseFloat(listingTimeAndPriceFormData.price) * 100,
      startTime: parseTimeValues(listingTimeAndPriceFormData.startTime),
    };
  };

  const submit = (ignoreWarnings: boolean) => {
    void processEditSubmission(
      generateHighlightedTableIds(),
      ignoreWarnings,
      processTimeAndPriceListingEdits(),
    );
  };
  const handleOnSubmit = () => submit(false);
  const handleOnConfirmSubmit = () => submit(true);

  const getRedirectPath = () => {
    if (existingListing.status === 'draft') {
      if (isFromFloorPlan) return OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_PATH;
      return OPERATIONS_LISTINGS_CALENDAR_DRAFT_PATH;
    }

    if (isFromFloorPlan) return OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH;
    return OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_PATH;
  };

  return (
    <>
      <form
        className={layoutStyles.pageWrapper}
        onSubmit={handleSubmit(handleOnSubmit)}
        noValidate
      >
        <EditListingPageHeader cancelPath={getRedirectPath()} />
        <EditListingTimeAndPricePageBody
          detailsControl={detailsControl}
          listingName={existingListing.name}
          timeAndPriceControl={timeAndPriceControl}
        />
      </form>
      <EditListingFloorPlanModalContainer
        handleOnConfirmSubmit={handleOnConfirmSubmit}
      />
    </>
  );
};
