import {
  addDays,
  addWeeks,
  hoursToMinutes,
  parseISO,
  startOfWeek,
  subDays,
} from 'date-fns';
import { compact, intersection, max, min, range } from 'lodash-es';
import { parseTimeString } from '@components/formInputs/timeValuesUtils';
import { type FloorPlanData } from '@shared/types/floorPlans';
import { ISODateToWeekDay, toISODateFormat } from '@utils/date';
import {
  ISOTimeAddMinutes,
  ISOTimeOf,
  ISOTimeSaturatingAddMinutes,
  ISOTimeToMinuteOfDay,
} from '@utils/time';
import type { Listing } from '../apiHelpers';
import type {
  ListingDetailsFormData,
  ListingTimeAndPriceFormData,
} from '../types';
import {
  type TimeRange,
  timeRangeIncludes,
  timeRangeOverlaps,
} from './timeRange';

export type ListingLocationState =
  | {
      isFromDraft?: boolean;
      isFromFloorPlan?: boolean;
      duplicateListingId?: string;
    }
  | undefined;

interface ListingLikeEntity {
  startTime: string;
  endTime: string;
  repeat: string[];
  startDate: string;
  endDate: string | null;
  turnTime: number;
}

const MINUTES_PER_DAY = hoursToMinutes(24);

/**
 * Applies the supplied mapper function to a range of integers
 * from 0 to N - 1 and returns the result as an array of length N.
 *
 * @param n - The number of elements to map.
 * @param mapper - The function that maps each element.
 * @returns An array of type `T` with `n` elements.
 *
 * @example
 * mapN(5, (n) => n * 2); // [0, 2, 4, 6, 8]
 */
export const mapN = <T>(n: number, mapper: (n: number) => T): T[] =>
  Array.from({ length: n }, (_, i) => mapper(i));

export const mapHalfHourIncrements = <T>(mapper: (n: number) => T): T[] =>
  mapN(24 * 2, (i) => mapper(i));

/**
 * Converts a boolean value indicating whether a seating arrangement
 * is communal to a corresponding seating type string.
 * @param isCommunal - A boolean value indicating whether the
 * seating arrangement is communal.
 * @returns The seating type string, either 'Communal' or 'Table'.
 */
export const seatingTypeFromIsCommunal = (isCommunal: boolean) =>
  isCommunal ? 'Communal' : 'Table';

/** Returns true if the listing has availability on the given date */
export const listingIsOnDate = (listing: ListingLikeEntity, date: string) =>
  listing.startDate <= date &&
  (!listing.endDate || listing.endDate >= date) &&
  listing.repeat.includes(ISODateToWeekDay(date).toString());

/** Returns true if the turn time goes past midnight */
export const listingHasRollover = (listing: ListingLikeEntity) =>
  ISOTimeToMinuteOfDay(listing.endTime) + listing.turnTime > MINUTES_PER_DAY;

/**
 * Calculates a list of time ranges which the given listing occupies for a given
 * date. There may be up to two time ranges since there may be overflow from the
 * previous day. Turn time is included in the time ranges.
 */
export const listingTimeRangesOnDate = (
  listing: ListingLikeEntity,
  date: string,
): TimeRange[] => {
  let rollover: TimeRange | null = null;
  let main: TimeRange | null = null;
  if (
    listingHasRollover(listing) &&
    listingIsOnDate(listing, toISODateFormat(subDays(parseISO(date), 1)))
  ) {
    rollover = {
      startTime: '00:00:00',
      endTime: ISOTimeAddMinutes(listing.endTime, listing.turnTime),
    };
  }
  if (listingIsOnDate(listing, date)) {
    main = {
      startTime: listing.startTime,
      endTime: ISOTimeSaturatingAddMinutes(listing.endTime, listing.turnTime),
    };
  }
  if (rollover && main && timeRangeOverlaps(rollover, main)) {
    return [{ startTime: rollover.startTime, endTime: main.endTime }];
  }
  return compact([rollover, main]);
};

/**
 * Generates an array of 96 strings representing the 15-minute
 * increments in a day, starting from '00:00:00' and ending at '23:45:00'.
 *
 * @returns An array of 96 strings representing the 15-minute increments in a day.
 *
 * @example
 * generateTimes(); // ['00:00:00', '00:15:00', '00:30:00', '00:45:00',…]
 */
export const generateTimes = () =>
  Array.from({ length: 24 }, (_, hour) =>
    Array.from({ length: 4 }, (__, i) => ISOTimeOf(hour, 15 * i)),
  ).flat();

export const listingIsOnDateWithRollover = (listing: Listing, date: string) =>
  listingTimeRangesOnDate(listing, date).length > 0;

export const listingsOverlap = (a: Listing, b: Listing): boolean => {
  const tables = intersection(
    a.highlightedFloorPlanTableIds,
    b.highlightedFloorPlanTableIds,
  );
  if (tables.length === 0) return false;

  const startDate = max([a.startDate, b.startDate])!;
  const endDateWithoutRollover = min(compact([a.endDate, b.endDate]));
  const endDate = endDateWithoutRollover
    ? toISODateFormat(addDays(parseISO(endDateWithoutRollover), 1))
    : undefined;
  if (endDate && endDate < startDate) return false;

  return range(8).some((daysToAdd) => {
    const date = toISODateFormat(addDays(startDate, daysToAdd));
    if (endDate && date > endDate) return false;

    const rangesA = listingTimeRangesOnDate(a, date);
    if (rangesA.length === 0) return false;
    const rangesB = listingTimeRangesOnDate(b, date);
    if (rangesB.length === 0) return false;
    return rangesA.some((rangeA) =>
      rangesB.some((rangeB) => timeRangeOverlaps(rangeA, rangeB)),
    );
  });
};

/**
 * Returns true if a given listing overlaps with a given time block on a calendar
 * It will account for turn time after the end time as well as the rollover to the next day
 *
 * @param startTime - start time of the range
 * @param endTime - end time of the range
 * @param date - date string in ISO format
 * @returns a filter function that takes in listing as a parameter
 */
export const isOverlappingInRange =
  (startTime: string, endTime: string, date: string) =>
  (listing: ListingLikeEntity) =>
    listingTimeRangesOnDate(listing, date).some((timeRange) =>
      timeRangeOverlaps(timeRange, { startTime, endTime }),
    );

/**
 * Returns true if a given listing overlaps with a given point in time
 * It will account for turn time after the end time as well as the rollover to the next day
 *
 * @param time - point in time
 * @param date - date string in ISO format
 * @returns a filter function that takes in listing as a parameter
 */
export const isOverlappingAtTime =
  (time: string, date: string) => (listing: ListingLikeEntity) =>
    listingTimeRangesOnDate(listing, date).some((timeRange) =>
      timeRangeIncludes(timeRange, time),
    );

export const getListingsForWeekByDate = (
  listings: Listing[],
  selectedDate: string,
): Listing[] => {
  const weekStart = startOfWeek(parseISO(selectedDate));
  const weekEnd = addWeeks(weekStart, 1);

  return listings.filter(
    (listing) =>
      parseISO(listing.startDate) < weekEnd &&
      (!listing.endDate || parseISO(listing.endDate) >= weekStart),
  );
};

export const listingDetailsFormDataFromListing = (
  listing: Listing,
  floorPlan: FloorPlanData | null,
): ListingDetailsFormData => {
  const highlightedTables = floorPlan
    ? floorPlan.floorPlanTables.filter((table) =>
        listing.highlightedFloorPlanTableIds.includes(table.id),
      )
    : [];
  return {
    highlightedTables,
    iconName: listing.iconName,
    interval: listing.interval,
    inventoryCount: listing.inventoryCount.toString(),
    isCommunal: listing.isCommunal,
    maximumGuests: listing.maximumGuests.toString(),
    minimumGuests: listing.minimumGuests.toString(),
    name: listing.name,
    publicName: listing.publicName,
    turnTime: listing.turnTime,
  };
};

export const listingTimeAndPriceFormDataFromListing = (
  listing: Listing,
): ListingTimeAndPriceFormData => ({
  endDate: listing.endDate || '',
  endTime: parseTimeString(listing.endTime),
  price: (listing.price / 100).toString(),
  repeat: listing.repeat,
  startDate: listing.startDate,
  startTime: parseTimeString(listing.startTime),
});
