// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HookAwareFormMultiSelect__dropdownIndicator___i8y4P{height:24px;rotate:-90deg;stroke:var(--darkBlue);width:24px}.HookAwareFormMultiSelect__error___oVvSb{color:var(--pink)}.HookAwareFormMultiSelect__labelClassName___CRHWT{align-items:flex-start;display:flex;flex-direction:row;justify-content:space-between;margin-top:0;width:100%}.HookAwareFormMultiSelect__labelClassName___CRHWT>span{font-size:16px;line-height:24px;margin-top:.75rem}.HookAwareFormMultiSelect__selectPlusError___JdBM5{display:flex;flex-direction:column;gap:.5rem;width:100%}", "",{"version":3,"sources":["webpack://./src/shared/components/hookAwareFormSelect/HookAwareFormMultiSelect.scss"],"names":[],"mappings":"AAAA,qDACE,WAAA,CACA,aAAA,CACA,sBAAA,CACA,UAAA,CAGF,yCACE,iBAAA,CAGF,kDACE,sBAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,YAAA,CACA,UAAA,CAEA,uDACE,cAAA,CACA,gBAAA,CACA,iBAAA,CAIJ,mDACE,YAAA,CACA,qBAAA,CACA,SAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownIndicator": "HookAwareFormMultiSelect__dropdownIndicator___i8y4P",
	"error": "HookAwareFormMultiSelect__error___oVvSb",
	"labelClassName": "HookAwareFormMultiSelect__labelClassName___CRHWT",
	"selectPlusError": "HookAwareFormMultiSelect__selectPlusError___JdBM5"
};
export default ___CSS_LOADER_EXPORT___;
