import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Card } from '@components/card/Card';
import { Icon } from '@components/icon/Icon';
import { IconButton } from '@components/iconButton/IconButton';
import { formatWeekdayList, toShortDateFormat } from '@utils/date';
import {
  ModalType,
  useModalContext,
} from 'restaurantAdmin/context/ModalContext';
import { OPERATIONS_EVENTS_UPDATE_PATH } from 'restaurantAdmin/paths';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../context/useRestaurant';
import type { RestaurantEvent } from './apiHelpers';
import styles from './RestaurantEventsList.scss';

interface RestaurantEventsTableProps {
  restaurantEvents: RestaurantEvent[];
  refetchRestaurantEvents: () => void;
}

export const RestaurantEventsList = ({
  restaurantEvents,
  refetchRestaurantEvents,
}: RestaurantEventsTableProps) => {
  const { id: restaurantId } = useRestaurant();
  const { openModal } = useModalContext();

  return (
    <ol aria-label="Restaurant Events" className={styles.eventList}>
      {restaurantEvents.map((restaurantEvent) => {
        const { description, endDate, id, repeat, startDate } = restaurantEvent;
        const shortStartDate = toShortDateFormat(startDate);
        const shortEndDate = endDate ? toShortDateFormat(endDate) : undefined;
        const oneDayEvent = shortStartDate === shortEndDate;

        return (
          <li className={styles.eventItem} key={id}>
            <Card>
              <header className={styles.cardHeader}>
                <h2 className={typography.h5}>Description</h2>
                <div>
                  <Link
                    className={cx(typography.c2, styles.editLink)}
                    state={{ restaurantEvent }}
                    title={`Edit ${description}`}
                    to={OPERATIONS_EVENTS_UPDATE_PATH}
                  >
                    <Icon ariaLabel={`Edit ${description}`} name="pencil" />
                  </Link>
                  <IconButton
                    ariaLabel={`Delete ${description}`}
                    className={styles.deleteButton}
                    iconName="trash"
                    onClick={() => {
                      openModal(ModalType.DeleteRestaurantEventConfirmation, {
                        refetchRestaurantEvents,
                        restaurantEventId: id,
                        restaurantId,
                      });
                    }}
                  />
                </div>
              </header>
              <p className={cx(typography.c2, styles.eventContent)}>
                {description}
              </p>
              <h3 className={cx(typography.h6, styles.eventSubheading)}>
                {oneDayEvent ? 'Date' : 'Start and end dates'}
              </h3>
              <p className={cx(typography.c2, styles.eventContent)}>
                {oneDayEvent
                  ? shortStartDate
                  : `${shortStartDate}–${shortEndDate || 'Never'}`}
              </p>
              {Boolean(repeat.length) && (
                <>
                  <h3 className={cx(typography.h6, styles.eventSubheading)}>
                    Repeat
                  </h3>
                  <p className={cx(typography.c2, styles.eventContent)}>
                    {formatWeekdayList(repeat)}
                  </p>
                </>
              )}
            </Card>
          </li>
        );
      })}
    </ol>
  );
};
