export const HUMAN_READABLE_ICON_NAMES = {
  barSeat: 'Bar seat',
  barSeatSmall: 'Bar seat',
  cabanaTable: 'Cabana with table',
  mergedTable: 'Merged table',
  ovlTable2Benches: 'Oval table with 2 benches',
  ovlTable6RndChairs: 'Oval table with 6 round chairs',
  recTable2Benches: 'Rectangular table with 2 benches',
  recTable2Couches: 'Rectangular table with 2 couches',
  recTable2LoveSeats: 'Rectangular table with 2 love seats',
  recTable2RndChairsOneSide:
    'Rectangular table with 2 round chairs on one side',
  recTable2SqrChairsAndBench:
    'Rectangular table with 2 square chairs and a bench',
  recTable3SqrChairs50PctWrapBench:
    'Rectangular table with 3 square chairs and a 50 percent wrap bench',
  recTable3SqrChairsAndBench:
    'Rectangular table with 3 square chairs and a bench',
  recTable4SqrChairs: 'Rectangular table with 4 square chairs',
  recTable5SqrChairs50PctWrapBench:
    'Rectangular table with 5 square chairs and a 50 percent wrap bench',
  recTable6RndChairs: 'Rectangular table with 6 round chairs',
  recTable6SqrChairs: 'Rectangular table with 6 square chairs',
  rndTable2RndChairs: 'Round table with 2 round chairs',
  rndTable4RndChairs: 'Round table with 4 round chairs',
  rndTable4SqrChairs: 'Round table with 4 square chairs',
  rndTable8RndChairs: 'Round table with 8 round chairs',
  rndTable50PctWrapBench: 'Round table with 50 percent wrap bench',
  rndTable50PctWrapCornerBench: 'Round table with 50 percent wrap corner bench',
  sqrTable1RndChair1SqrChair:
    'Square table with 1 round chair and 1 square chair',
  sqrTable2RndChairs: 'Square table with 2 round chairs',
  sqrTable2SqrChairs: 'Square table with 2 square chairs',
  sqrTable4RndChairs: 'Square table with 4 round chairs',
  sqrTable4SqrChairs: 'Square table with 4 square chairs',
  sqrTable50PctWrapBench: 'Square table with 50 percent wrap bench',
  sqrTable75PctWrapBench: 'Square table with 75 percent wrap bench',
  sqrTable75PctWrapCornerBench:
    'Square table with 75 percent wrap corner bench',
  twoSqrTable2LoveSeats3ArmChairs:
    '2 square tables with 2 love seats and 3 arm chairs',
};

export const ICON_NAMES = Object.keys(HUMAN_READABLE_ICON_NAMES);

export type IconNameType = keyof typeof HUMAN_READABLE_ICON_NAMES;

export interface ListingId {
  id: string;
}

export interface FloorPlanTableData {
  iconName: IconNameType;
  iconWidthScale: string;
  id: string;
  interval: number;
  left: string;
  maximumGuests: number;
  minimumGuests: number;
  name: string;
  orientation: string;
  pulse?: boolean;
  listings: ListingId[];
  top: string;
  turnTime: number;
}

export interface FloorPlanData {
  id: string;
  backgroundSrc?: string;
  floorPlanTables: FloorPlanTableData[];
}

export interface FloorPlanSnapshotTableData {
  iconName: IconNameType;
  iconWidthScale: string;
  id: string;
  isHighlighted: boolean;
  left: string;
  name: string;
  orientation: string;
  top: string;
}

export interface FloorPlanSnapshotData {
  backgroundSrc: string;
  floorPlanTables: FloorPlanSnapshotTableData[];
}
