import type { FeatureConfig } from '@shared/context/FeatureFlagContext';
import type { Environment } from '@shared/environments';

export type RestaurantAdminFeatureFlag = 'testFlag' | 'pizzaTrackerFlag';

export const featureFlagConfig: Readonly<
  Record<Environment, FeatureConfig<RestaurantAdminFeatureFlag>>
> = Object.freeze({
  development: {
    testFlag: true,
    pizzaTrackerFlag: true,
  },
  ci: {
    testFlag: true,
    pizzaTrackerFlag: true,
  },
  staging: {
    testFlag: true,
    pizzaTrackerFlag: true,
  },
  demo: {
    testFlag: false,
    pizzaTrackerFlag: false,
  },
  production: {
    testFlag: false,
    pizzaTrackerFlag: false,
  },
});
