import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createResponseError } from '@shared/api/createResponseError';
import { isErrorResponse, isSuccessResponse } from '@shared/types/apiHelpers';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { useError } from 'restaurantAdmin/errors/useError';
import {
  getAvailableTimesForRestaurantId,
  getHostFloorPlanForRestaurantId,
  type HostFloorPlanData,
  type HostFloorPlanTable,
} from 'restaurantAdmin/floorPlans/apiHelpers';
import {
  type AvailableWalkInTime,
  getInfiniteOccupants,
  type ServiceOccupant,
} from '../apiHelpers';
import { useFetchOccupantDetails } from '../hooks/useFetchOccupantDetails';
import { type GetPage, useLoadInfinite } from '../hooks/useLoadInfinite';
import type { FloorPlanOccupant, OccupantType } from './types';

export const enum SidePanelSheetMode {
  None = 'none',
  AddToWaitList = 'addToWaitList',
  OccupantDetails = 'occupantDetails',
  SeatWalkIn = 'seatWalkIn',
  MergeUnmerge = 'mergeUnmerge',
  WaitListDetails = 'waitListDetails',
}

export type SidePanelSheetState =
  | { mode: SidePanelSheetMode.None }
  | { mode: SidePanelSheetMode.AddToWaitList }
  | { mode: SidePanelSheetMode.SeatWalkIn; state: HostFloorPlanTable }
  | {
      mode: SidePanelSheetMode.OccupantDetails;
      state: FloorPlanOccupant;
    }
  | { mode: SidePanelSheetMode.MergeUnmerge }
  | {
      mode: SidePanelSheetMode.WaitListDetails;
    };

export type AvailableWalkInTimeDictionary = Record<string, AvailableWalkInTime>;

export interface OccupantPositionValues {
  count: number;
  position: number;
}

const EMPTY_AVAILABLE_TIMES: AvailableWalkInTimeDictionary = {};

export interface ReservationServiceContextState {
  fetchSubsequentOccupants: () => void;
  floorPlan: HostFloorPlanData | undefined;
  availableTimesByFloorPlanTableId: AvailableWalkInTimeDictionary;
  handleOnClickNextOccupant: () => void;
  handleOnClickPreviousOccupant: () => void;
  handleOnSelectOccupant: (occupantId: string, type: OccupantType) => void;
  handleSeatedTableOnClick: (occupant: FloorPlanOccupant) => void;
  hasMoreOccupants: boolean;
  isLoading: boolean;
  isFloorPlanLoading: boolean;
  occupants: ServiceOccupant[];
  occupantPositionValues: OccupantPositionValues;
  refreshOccupants: () => void;
  refreshFloorPlan: () => void;
  resetViewMode: () => void;
  selectedOccupant: ServiceOccupant | undefined;
  setSidePanelSheet: (state: SidePanelSheetState) => void;
  shouldShowCarousel: boolean;
  shouldShowTimers: boolean;
  toggleShouldShowTimers: () => void;
  /** The action sheet that is displaying on top of the default view */
  sidePanelSheet: SidePanelSheetState;
}

const ReservationServiceContext = createContext<ReservationServiceContextState>(
  {} as ReservationServiceContextState,
);

export const useReservationServiceContext =
  (): ReservationServiceContextState => useContext(ReservationServiceContext);

const INITIAL_OCCUPANT_LIMIT = 50;
const SUBSEQUENT_OCCUPANT_LIMIT = 25;

export const ReservationServiceContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { id: restaurantId } = useRestaurant();

  const getServiceOccupantsPage: GetPage<ServiceOccupant> = async (
    limit,
    offset,
  ) => {
    const response = await getInfiniteOccupants(restaurantId, limit, offset);
    if (isErrorResponse(response)) throw createResponseError(response);
    return response;
  };

  const {
    hasMore: hasMoreOccupants,
    isLoading: isOccupantsLoading,
    items: occupants,
    loadFirstPage: loadOccupantsFirstPage,
    loadNextPage: fetchSubsequentOccupants,
  } = useLoadInfinite({
    firstPageSize: INITIAL_OCCUPANT_LIMIT,
    defaultPageSize: SUBSEQUENT_OCCUPANT_LIMIT,
    getPage: getServiceOccupantsPage,
  });
  const [sidePanelSheet, setSidePanelSheet] = useState<SidePanelSheetState>({
    mode: SidePanelSheetMode.None,
  });
  const [shouldShowTimers, setShouldShowTimers] = useState(false);
  const [floorPlan, setFloorPlan] = useState<HostFloorPlanData>();
  const [
    availableTimesByFloorPlanTableId,
    setAvailableTimesByFloorPlanTableId,
  ] = useState<AvailableWalkInTimeDictionary>(EMPTY_AVAILABLE_TIMES);
  const clearAvailableTimesByFloorPlanTableId = () =>
    setAvailableTimesByFloorPlanTableId(EMPTY_AVAILABLE_TIMES);

  const [isFloorPlanLoading, setFloorPlanLoading] = useState(false);

  const selectedOccupantIndex =
    sidePanelSheet.mode === SidePanelSheetMode.OccupantDetails
      ? occupants.findIndex((o) => o.id === sidePanelSheet.state.id)
      : -1;
  const selectedOccupantFromOccupants =
    selectedOccupantIndex === -1 ? undefined : occupants[selectedOccupantIndex];
  const setError = useError();

  const fetchAvailableTimes = async () => {
    const availableTimesResponse =
      await getAvailableTimesForRestaurantId(restaurantId);
    if (isSuccessResponse(availableTimesResponse)) {
      const result = availableTimesResponse.reduce((dictionary, time) => {
        // eslint-disable-next-line no-param-reassign
        dictionary[time.floorPlanTableId] = time.availableTime;
        return dictionary;
      }, {} as AvailableWalkInTimeDictionary);
      setAvailableTimesByFloorPlanTableId(result);
    }
  };

  const fetchHostFloorPlan = () => {
    void (async () => {
      setFloorPlanLoading(true);
      try {
        const floorPlanResponse =
          await getHostFloorPlanForRestaurantId(restaurantId);
        if (isSuccessResponse(floorPlanResponse))
          setFloorPlan(floorPlanResponse);
        if (shouldShowTimers) await fetchAvailableTimes();
      } catch (e) {
        setError(e);
      } finally {
        setFloorPlanLoading(false);
      }
    })();
  };

  const selectedOccupantIdToFetch = useMemo(() => {
    const shouldFetch =
      sidePanelSheet.mode === SidePanelSheetMode.OccupantDetails &&
      !isOccupantsLoading &&
      !selectedOccupantFromOccupants;
    return shouldFetch
      ? { id: sidePanelSheet.state.id, type: sidePanelSheet.state.type }
      : null;
  }, [sidePanelSheet, isOccupantsLoading, selectedOccupantFromOccupants]);

  const {
    occupant: selectedOccupantFetched,
    invalidate: refreshSelectedOccupant,
  } = useFetchOccupantDetails(selectedOccupantIdToFetch);

  const selectedOccupant =
    selectedOccupantFromOccupants || selectedOccupantFetched;

  const resetViewMode = () => {
    setSidePanelSheet({ mode: SidePanelSheetMode.None });
  };

  const handleOnClickNextOccupant = () => {
    const nextIndex = Math.min(occupants.length - 1, selectedOccupantIndex + 1);
    const nextOccupant = occupants[nextIndex];
    setSidePanelSheet({
      mode: SidePanelSheetMode.OccupantDetails,
      state: { id: nextOccupant.id, type: nextOccupant.type },
    });
  };

  const handleOnClickPreviousOccupant = () => {
    const previousIndex = Math.max(0, selectedOccupantIndex - 1);
    const previousOccupant = occupants[previousIndex];
    setSidePanelSheet({
      mode: SidePanelSheetMode.OccupantDetails,
      state: { id: previousOccupant.id, type: previousOccupant.type },
    });
  };

  const handleOnSelectOccupant = (occupantId: string, type: OccupantType) => {
    setSidePanelSheet({
      mode: SidePanelSheetMode.OccupantDetails,
      state: { id: occupantId, type },
    });
  };

  const handleSeatedTableOnClick = (occupant: FloorPlanOccupant) => {
    setSidePanelSheet({
      mode: SidePanelSheetMode.OccupantDetails,
      state: occupant,
    });
  };

  const toggleShouldShowTimers = () => {
    if (!shouldShowTimers) {
      setShouldShowTimers(true);
      void fetchAvailableTimes();
    } else {
      setShouldShowTimers(false);
      clearAvailableTimesByFloorPlanTableId();
    }
  };

  const refreshOccupants = () => {
    loadOccupantsFirstPage();
    refreshSelectedOccupant();
  };

  useEffect(() => {
    loadOccupantsFirstPage();
    fetchHostFloorPlan();
  }, [restaurantId]);

  const value = useMemo<ReservationServiceContextState>(
    () => ({
      availableTimesByFloorPlanTableId,
      fetchSubsequentOccupants,
      floorPlan,
      handleOnClickNextOccupant,
      handleOnClickPreviousOccupant,
      handleOnSelectOccupant,
      handleSeatedTableOnClick,
      hasMoreOccupants,
      isFloorPlanLoading,
      isLoading: isFloorPlanLoading || isOccupantsLoading,
      occupantPosition: `Reservation ${selectedOccupantIndex + 1} of ${occupants.length}`,
      occupants,
      occupantPositionValues: {
        count: occupants.length,
        position: selectedOccupantIndex + 1,
      },
      refreshFloorPlan: fetchHostFloorPlan,
      refreshOccupants,
      refreshSelectedOccupant,
      resetViewMode,
      selectedOccupant,
      setSidePanelSheet,
      shouldShowCarousel: !!selectedOccupantFromOccupants,
      shouldShowTimers,
      sidePanelSheet,
      toggleShouldShowTimers,
    }),
    [
      floorPlan,
      availableTimesByFloorPlanTableId,
      hasMoreOccupants,
      isFloorPlanLoading,
      occupants,
      selectedOccupant,
      sidePanelSheet,
      shouldShowTimers,
    ],
  );

  return (
    <ReservationServiceContext.Provider value={value}>
      {children}
    </ReservationServiceContext.Provider>
  );
};
