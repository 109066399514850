// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditListingFloorPlanModalContainer__modalContent___WHNpT{text-align:center}.EditListingFloorPlanModalContainer__modalContent___WHNpT .EditListingFloorPlanModalContainer__message___WIOWl{color:var(--white70);max-width:30em}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/edit/EditListingFloorPlanModalContainer.scss"],"names":[],"mappings":"AAAA,0DACE,iBAAA,CAEA,+GACE,oBAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": "EditListingFloorPlanModalContainer__modalContent___WHNpT",
	"message": "EditListingFloorPlanModalContainer__message___WIOWl"
};
export default ___CSS_LOADER_EXPORT___;
