import {
  FloorPlan,
  type FloorPlanTablesRenderer,
} from '@components/floorPlan/FloorPlan';
import { errorToast } from '@components/toasts/Toasts';
import {
  getErrorResponseMessage,
  isSuccessResponse,
} from '@shared/types/apiHelpers';
import {
  ModalType,
  useModalContext,
} from 'restaurantAdmin/context/ModalContext';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import type { HostFloorPlanTable } from 'restaurantAdmin/floorPlans/apiHelpers';
import {
  changeWalkInSeat,
  getWalkInAvailability,
  type WalkInAvailability,
} from '../../apiHelpers';
import {
  type AvailableWalkInTimeDictionary,
  useReservationServiceContext,
} from '../state/ReservationServiceContext';
import { SeatedFloorPlanTableFactory } from './SeatedFloorPlanTableFactory';

export interface WalkInSeatModeFloorPlanProps {
  backgroundSrc?: string;
  className?: string;
  refreshOccupantsAndFloorPlan: () => void;
  exitSeatMode: () => void;
  tables: HostFloorPlanTable[];
  availableTimesByFloorPlanTableId: AvailableWalkInTimeDictionary;
}

interface ChangeWalkInSeatState {
  hostFloorPlanTable: HostFloorPlanTable;
  walkInAvailability: WalkInAvailability;
}

export const WalkInSeatModeFloorPlan = ({
  backgroundSrc,
  className,
  refreshOccupantsAndFloorPlan,
  exitSeatMode,
  tables,
  availableTimesByFloorPlanTableId,
}: WalkInSeatModeFloorPlanProps) => {
  const { openModal } = useModalContext();
  const restaurant = useRestaurant();
  const { resetViewMode, selectedOccupant } = useReservationServiceContext();

  if (!selectedOccupant) return null;

  const changeSeat = async (changeWalkInSeatData: ChangeWalkInSeatState) => {
    if (!changeWalkInSeatData) return;

    const response = await changeWalkInSeat({
      walkInId: selectedOccupant.id,
      restaurantId: restaurant.id,
      floorPlanTableId: changeWalkInSeatData.hostFloorPlanTable.id,
      turnTime: changeWalkInSeatData.walkInAvailability.turnTime,
      hasAvailability: changeWalkInSeatData.walkInAvailability.hasAvailability,
    });

    if (response.ok) {
      resetViewMode();
      exitSeatMode();
    } else {
      const errorMessage = await getErrorResponseMessage(response);
      errorToast({ message: errorMessage });
    }
    refreshOccupantsAndFloorPlan();
  };

  const onChangeWalkInSeating = async (
    hostFloorPlanTable: HostFloorPlanTable,
  ) => {
    const response = await getWalkInAvailability({
      floorPlanTableId: hostFloorPlanTable.id,
      guestCount: selectedOccupant.guestCount,
      restaurantId: restaurant.id,
    });
    if (isSuccessResponse(response)) {
      const changeSeatState = {
        hostFloorPlanTable,
        walkInAvailability: response,
      };

      if (!response.hasAvailability && changeSeatState) {
        openModal(ModalType.WalkInSeatingConflict, {
          handleConfirm: () => {
            void changeSeat(changeSeatState);
          },
        });
        return;
      }

      if (response.turnTime !== null && changeSeatState) {
        openModal(ModalType.WalkInTurnTimeInformation, {
          handleConfirm: () => {
            void changeSeat(changeSeatState);
          },
          turnTime: changeSeatState.walkInAvailability.turnTime,
        });

        return;
      }

      await changeSeat({
        hostFloorPlanTable,
        walkInAvailability: response,
      });
    } else {
      errorToast({
        message: 'Failed to confirm seat availability. Please try again.',
      });
    }
  };

  const floorPlanTablesRenderer: FloorPlanTablesRenderer = (tableIconScale) => (
    <>
      {tables.map((floorPlanTable) => (
        <SeatedFloorPlanTableFactory
          floorPlanTable={floorPlanTable}
          handleTableOnClick={onChangeWalkInSeating}
          key={floorPlanTable.id}
          availableTime={
            availableTimesByFloorPlanTableId[floorPlanTable.id] || null
          }
          tableIconScale={tableIconScale}
        />
      ))}
    </>
  );

  return (
    <FloorPlan
      backgroundSrc={backgroundSrc}
      className={className}
      floorPlanTablesRenderer={floorPlanTablesRenderer}
    />
  );
};
