import cx from 'classnames';
import { get } from 'lodash-es';
import {
  type Control,
  type FieldErrors,
  type FieldPath,
  type FieldValues,
  useController,
  type UseControllerProps,
  useWatch,
} from 'react-hook-form';
import type {
  DropdownIndicatorProps,
  MultiValue,
  StylesConfig,
} from 'react-select';
import { components } from 'react-select';
import type { SetTooltipIsOpenDispatch } from '@components/formInputTooltip/FormInputTooltip';
import type { FormSelectItem } from '@components/formSelect/FormSelect';
import { FormSelect } from '@components/formSelect/FormSelect';
import { Icon } from '@components/icon/Icon';
import { ValidationErrorMessage } from '@components/validationErrorMessage/ValidationErrorMessage';
import styles from './HookAwareFormMultiSelect.scss';

export interface HookAwareFormMultiSelectProps<
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
> {
  containerClassName?: string;
  control?: Control<T>;
  errors: FieldErrors<T>;
  formSelectClassName?: string;
  handleChange: (
    value: MultiValue<FormSelectItem>,
  ) => MultiValue<FormSelectItem>;
  hasError: boolean;
  isDisabled: boolean;
  label: string;
  name: Name;
  options: FormSelectItem[];
  setTooltipIsOpen: SetTooltipIsOpenDispatch;
  shouldUnregister?: boolean;
  tooltipText?: string;
  validations?: UseControllerProps<T, Name>['rules'];
}

const BOLD = 600;
const REGULAR = 400;

export const customStyles = (
  hasError = false,
  isDisabled = false,
): StylesConfig<FormSelectItem, true> => ({
  container: (provided) => ({
    ...provided,
    backgroundColor: 'var(--darkGrey100)',
    border: 'none',
    borderRadius: '5px',
    color: 'var(--darkBlue)',
    minHeight: '3rem',
    width: '15rem',
  }),
  control: (provided, state) => ({
    ...provided,
    boxSizing: 'border-box',
    backgroundColor: hasError ? 'var(--red100)' : 'var(--darkGrey100)',
    '&:hover': {
      border: '3px solid var(--white);',
    },
    border: state.isFocused
      ? '3px solid var(--white);'
      : '3px solid var(--darkGrey100)',
    borderRadius: '5px',
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'var(--darkBlue)',
    '&:hover': {
      color: 'var(--darkBlue)',
    },
  }),
  input: (provided) => ({
    ...provided,
    color: isDisabled ? 'var(--primary200)' : 'var(--white)',
    margin: 0,
    paddingBottom: 0,
    paddingtop: '0.00',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'var(--gold)' : 'var(--primary400)',
    color: state.isSelected ? 'var(--black)' : 'var(--white)',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: state.isSelected ? BOLD : REGULAR,
    lineHeight: '16px',
    '&:hover': {
      backgroundColor: 'var(--gold)',
      color: 'var(--black)',
      fontWeight: BOLD,
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'var(--primary400)',
    color: 'var(--white)',
    borderRadius: 0,
  }),
  menuList: (provided) => ({ ...provided, padding: 0 }),
  placeholder: (provided) => ({
    ...provided,
    color: 'transparent',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: isDisabled ? 'var(--primary200)' : 'var(--darkBlue)',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 0.5rem',
  }),
});

const DropdownIndicator = (
  props: DropdownIndicatorProps<FormSelectItem, true>,
) => (
  <components.DropdownIndicator {...props}>
    <Icon name="chevron" className={styles.dropdownIndicator} />
  </components.DropdownIndicator>
);

export const HookAwareFormMultiSelect = <
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
>({
  containerClassName,
  control,
  errors,
  formSelectClassName,
  handleChange,
  hasError,
  isDisabled,
  label,
  name,
  options,
  setTooltipIsOpen,
  shouldUnregister = false,
  tooltipText,
  validations,
}: HookAwareFormMultiSelectProps<T, Name>) => {
  const { field } = useController({
    control,
    name,
    rules: validations,
    shouldUnregister,
  });
  const watch = useWatch({ control });
  const value = get(watch, name);

  const onChange = (selection: MultiValue<FormSelectItem>) => {
    const newValue = handleChange(selection);
    field.onChange(newValue);
  };

  return (
    <div className={cx(styles.selectPlusError, containerClassName)}>
      <FormSelect
        className={cx(styles.select, formSelectClassName)}
        components={{ DropdownIndicator }}
        id={name}
        isDisabled={isDisabled}
        isMulti
        label={label}
        labelClassName={cx({
          [styles.labelClassName]: true,
          [styles.error]: hasError,
        })}
        name={name}
        onChange={onChange}
        options={options}
        setTooltipIsOpen={setTooltipIsOpen}
        styles={customStyles(hasError)}
        tooltipText={tooltipText}
        value={value}
      />
      <ValidationErrorMessage errors={errors} label={label} name={name} />
    </div>
  );
};
