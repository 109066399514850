import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';

export interface WalkInSeatingConflictModalProps {
  closeModal: () => void;
  handleConfirm: () => void;
}

export const WalkInSeatingConflictModal = ({
  closeModal,
  handleConfirm,
}: WalkInSeatingConflictModalProps) => (
  <Modal
    disablePortal
    isOpen
    onClose={closeModal}
    title="Seating Conflict Warning"
    subtitle="The walk-in you are about to seat conflicts with an existing reservation."
  >
    <ModalActions>
      <Button
        label="Cancel"
        onClick={closeModal}
        variant={ButtonVariants.Tertiary}
      />
      <Button
        label="Continue"
        onClick={() => {
          handleConfirm();
          closeModal();
        }}
        variant={ButtonVariants.Primary}
      />
    </ModalActions>
  </Modal>
);
