import { Kebab } from '@components/kebab/Kebab';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { getErrorResponseMessage } from '@shared/types/apiHelpers';
import { useError } from 'restaurantAdmin/errors/useError';
import { ModalType, useModalContext } from '../../context/ModalContext';
import { useRestaurant } from '../../context/useRestaurant';
import { type Admin, resendInvite } from './apiHelpers';

export interface TeamKebabProps {
  admin: Admin;
  refetchRestaurantAdmins: () => void;
}

export const TeamKebab = ({
  admin,
  refetchRestaurantAdmins,
}: TeamKebabProps) => {
  const restaurant = useRestaurant();
  const { openModal } = useModalContext();
  const setError = useError();

  const handleResendInvite = async () => {
    try {
      const response = await resendInvite(restaurant.id, admin.email);

      if (response.ok) {
        successToast({ message: 'The invite was re-sent successfully' });
      }
      if (!response.ok) {
        const errorMessage = await getErrorResponseMessage(response);
        errorToast({ message: errorMessage });
      }
    } catch (error) {
      setError(error);
    }
  };

  const options = [
    {
      disabled: admin.status !== 'active',
      label: 'Deactivate',
      onClick: () =>
        openModal(ModalType.DeactivateAdminConfirmation, {
          admin,
          refetchRestaurantAdmins,
        }),
    },
    {
      disabled: admin.status !== 'invited',
      label: 'Resend Invite',
      onClick: handleResendInvite,
    },
  ];

  return <Kebab options={options} />;
};
