import type { ApiResponse } from '@shared/types/apiHelpers';
import type { IconNameType } from '@shared/types/floorPlans';
import { api } from '../../api';
import API from '../../api/apiClient';

export interface CreateListingPayload {
  endDate: string | null;
  endTime: string;
  highlightedFloorPlanTableIds: string[];
  iconName: IconNameType;
  ignoreWarnings?: boolean;
  interval: number;
  inventoryCount: number;
  isCommunal: boolean;
  maximumGuests: number;
  minimumGuests: number;
  name: string;
  price: number;
  repeat: string[];
  startDate: string;
  startTime: string;
  publicName: string;
  turnTime: number;
}

export interface EditListingPayload {
  endDate: string | undefined;
  endTime: string;
  highlightedFloorPlanTableIds: string[];
  iconName: IconNameType;
  ignoreWarnings: boolean;
  interval: number;
  inventoryCount: number;
  maximumGuests: number;
  minimumGuests: number;
  name: string;
  price: number;
  repeat: string[];
  startDate: string;
  startTime: string;
  publicName: string;
}

export interface ListingWarnings {
  warnings: string[];
}

export interface NewListing {
  createdListingId: string;
}

export interface Listing {
  endDate: string | null;
  endTime: string;
  highlightedFloorPlanTableIds: string[];
  iconName: IconNameType;
  id: string;
  interval: number;
  inventoryCount: number;
  isCommunal: boolean;
  maximumGuests: number;
  minimumGuests: number;
  name: string;
  price: number;
  publicName: string;
  repeat: string[];
  restaurantId: string;
  startDate: string;
  startTime: string;
  status: 'active' | 'draft';
  turnTime: number;
}

interface ListingHasConflicts {
  hasConflicts: boolean;
}

export const createListing = async (
  restaurantId: string,
  listing: CreateListingPayload,
  isDraft?: boolean,
): Promise<ApiResponse<ListingWarnings | NewListing>> => {
  const response = await API.post(
    `/restaurants/${restaurantId}/listings${isDraft ? '?draft' : ''}`,
    listing,
  );
  return response.json();
};

export const deleteListing = async (
  restaurantId: string,
  listingId: string,
): Promise<Response> =>
  API.delete(`/restaurants/${restaurantId}/listings/${listingId}`);

export const editListing = async (
  restaurantId: string,
  listingId: string,
  listing: EditListingPayload,
): Promise<ApiResponse<ListingWarnings>> => {
  const response = await API.put(
    `/restaurants/${restaurantId}/listings/${listingId}`,
    listing,
  );
  return response.json();
};

export const getListing = async (
  listingId: string,
  restaurantId: string,
  signal?: AbortSignal,
): Promise<ApiResponse<Listing>> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/listings/${listingId}`,
    {
      signal,
    },
  );
  return response.json();
};

export const getListings = async (
  restaurantId: string,
  isDraft?: boolean,
  signal?: AbortSignal,
): Promise<Listing[]> =>
  api.get(`/restaurants/${restaurantId}/listings${isDraft ? '?draft' : ''}`, {
    signal,
  });

export const unpublishListing = async (
  restaurantId: string,
  listingId: string,
): Promise<ApiResponse<object>> => {
  const response = await API.post(
    `/restaurants/${restaurantId}/listings/${listingId}/unpublish`,
  );

  if (!response.ok) {
    return response.json();
  }
  return {};
};

export const publishListing = async (
  restaurantId: string,
  listingId: string,
): Promise<ApiResponse<object>> => {
  const response = await API.post(
    `/restaurants/${restaurantId}/listings/${listingId}/publish`,
  );

  if (!response.ok) {
    return response.json();
  }
  return {};
};

export const checkListingHasConflicts = async (
  restaurantId: string,
  listingId: string,
  signal?: AbortSignal,
): Promise<ApiResponse<ListingHasConflicts>> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/listings/${listingId}/has-conflicts`,
    {
      signal,
    },
  );
  return response.json();
};
