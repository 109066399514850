import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import styles from './GuestTagPill.scss';

interface GuestTagPillProps {
  title: string;
  category: string;
  createdBy: string;
}

const VIP = 'VIP';

export const GuestTagPill = ({
  title,
  category,
  createdBy,
}: GuestTagPillProps) => (
  <li className={styles.tag}>
    <div
      className={cx({
        [styles.pill]: true,
        [typography.t2]: true,
        [styles.green]: ['allergies', 'dietary restrictions'].includes(
          category,
        ),
        [styles.white]: category === 'guest attributes',
        [styles.gold]: category === 'other',
      })}
    >
      {title === VIP && <Icon ariaLabel="crown icon" name="crown" />}
      {title}
    </div>
    <span className={typography.t2}>{`Added by: ${createdBy}`}</span>
  </li>
);
