/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import { useOverlay } from '@shared/hooks/useOverlay';
import { DeleteRestaurantEventConfirmationModal } from 'restaurantAdmin/operations/events/DeleteRestaurantEventConfirmationModal';
import { CloseRestaurantModal } from 'restaurantAdmin/reservations/occupants/pageHeader/CloseRestaurantModal';
import { OpenRestaurantModal } from 'restaurantAdmin/reservations/occupants/pageHeader/OpenRestaurantModal';
import { WalkInSeatingConflictModal } from 'restaurantAdmin/reservations/service/sidePanel/WalkInSeatingConflictModal';
import { WalkInTurnTimeInformationModal } from 'restaurantAdmin/reservations/service/sidePanel/WalkInTurnTimeInformationModal';
import { SignOutModal } from '../auth/SignOutModal';
import { SeatOutsideListingConfirmationModal } from '../reservations/service/SeatOutsideListingConfirmationModal';
import { RemoveFromWaitListConfirmationModal } from '../reservations/service/sidePanel/waitList/RemoveFromWaitListConfirmationModal';
import { DeactivateAdminConfirmationModal } from '../settings/team/DeactivateAdminConfirmationModal';

export interface ModalContext {
  currentModal: ModalType | null;
  closeModal: () => void;
  /** @deprecated render the `<Modal>` directly, if possible. See RefundButton for an example. */
  openModal: (modalType: ModalType, props?: object) => void;
}

export const ModalContext = createContext<ModalContext>({} as ModalContext);
ModalContext.displayName = 'Modal (Admin)';

/** @deprecated render the `<Modal>` directly, if possible. See RefundButton for an example. */
export const useModalContext = () => useContext(ModalContext);

export enum ModalType {
  CloseRestaurant = 'closeRestaurant',
  DeactivateAdminConfirmation = 'deactivateAdminConfirmation',
  DeleteRestaurantEventConfirmation = 'deleteRestaurantEventConfirmation',
  OpenRestaurant = 'openRestaurant',
  RemoveFromWaitListConfirmation = 'removeFromWaitListConfirmation',
  SeatOutsideListingConfirmation = 'seatOutsideListingConfirmation',
  SignOut = 'signOut',
  UnseatGuestConfirmation = 'unseatGuestConfirmation',
  WalkInSeatingConflict = 'walkInSeatingConflict',
  WalkInTurnTimeInformation = 'walkInTurnTimeInformation',
}

export const ModalContextProvider = ({ children }: { children: ReactNode }) => {
  const [currentModal, setCurrentModal] = useState<ModalType | null>(null);
  const [modalProps, setModalProps] = useState<any>({});

  useOverlay(!!currentModal);

  const closeModal = () => setCurrentModal(null);
  const openModal = (modalType: ModalType, props: object = {}) => {
    setModalProps(props);
    setCurrentModal(modalType);
  };

  const value = useMemo<ModalContext>(
    () => ({
      currentModal,
      closeModal,
      openModal,
    }),
    [currentModal],
  );

  return (
    <ModalContext.Provider value={value}>
      {children}
      {currentModal === ModalType.CloseRestaurant && (
        <CloseRestaurantModal closeModal={closeModal} {...modalProps} />
      )}
      {currentModal === ModalType.DeleteRestaurantEventConfirmation && (
        <DeleteRestaurantEventConfirmationModal
          closeModal={closeModal}
          {...modalProps}
        />
      )}
      {currentModal === ModalType.OpenRestaurant && (
        <OpenRestaurantModal closeModal={closeModal} {...modalProps} />
      )}
      {currentModal === ModalType.RemoveFromWaitListConfirmation && (
        <RemoveFromWaitListConfirmationModal
          closeModal={closeModal}
          {...modalProps}
        />
      )}
      {currentModal === ModalType.SeatOutsideListingConfirmation && (
        <SeatOutsideListingConfirmationModal
          closeModal={closeModal}
          {...modalProps}
        />
      )}
      {currentModal === ModalType.SignOut && (
        <SignOutModal closeModal={closeModal} />
      )}
      {currentModal === ModalType.DeactivateAdminConfirmation && (
        <DeactivateAdminConfirmationModal
          closeModal={closeModal}
          {...modalProps}
        />
      )}
      {currentModal === ModalType.WalkInSeatingConflict && (
        <WalkInSeatingConflictModal closeModal={closeModal} {...modalProps} />
      )}
      {currentModal === ModalType.WalkInTurnTimeInformation && (
        <WalkInTurnTimeInformationModal
          closeModal={closeModal}
          {...modalProps}
        />
      )}
    </ModalContext.Provider>
  );
};
