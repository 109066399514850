import { useEffect, useState } from 'react';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import { formatPhone } from '@utils/formatPhone';
import typography from '~styles/typography.scss';
import { useAuth } from '../../context/AuthContext';
import { useRestaurant } from '../../context/useRestaurant';
import { PageContent } from '../../layout/PageContent';
import { PageHeader } from '../../layout/PageHeader';
import { type AdminRestaurant, getAdminRestaurant } from '../team/apiHelpers';
import { CancellationPolicySettings } from './cancellationPolicy/CancellationPolicySettings';
import { CoverLimitSettings } from './coverLimit/CoverLimitSettings';
import styles from './GeneralPage.scss';
import { MaxGuestCountSettings } from './maxGuestCount/MaxGuestCountSettings';
import { ScheduleSettings } from './schedule/ScheduleSettings';
import { RestaurantSettingsSection } from './shared/RestaurantSettingsSection';

export const GeneralPage = () => {
  const [restaurant, setRestaurant] = useState<AdminRestaurant>();

  const { refreshRestaurants: authRefreshRestaurants } = useAuth();
  const { id: restaurantId } = useRestaurant();

  const fetchAdminRestaurant = async () => {
    const response = await getAdminRestaurant(restaurantId);
    if (isSuccessResponse(response)) {
      setRestaurant(response);
    }
  };

  const refreshRestaurant = async () => {
    authRefreshRestaurants();
    await fetchAdminRestaurant();
  };

  useEffect(() => {
    void fetchAdminRestaurant();
  }, []);

  if (!restaurant) return <div />;

  return (
    <>
      <PageHeader title="Settings" />
      <PageContent className={styles.page}>
        <RestaurantSettingsSection heading="Details">
          <h3 className={typography.h6}>Name</h3>
          <div className={typography.c2}>{restaurant.name}</div>

          <h3 className={typography.h6}>Description</h3>
          <div className={typography.c2}>{restaurant.description}</div>

          <h3 className={typography.h6}>Website</h3>
          <div className={typography.c2}>{restaurant.website}</div>

          <h3 className={typography.h6}>Phone Number</h3>
          <div className={typography.c2}>{formatPhone(restaurant.phone)}</div>
        </RestaurantSettingsSection>

        <RestaurantSettingsSection heading="Address">
          <h3 className={typography.h6}>Address</h3>
          <div className={typography.c2}>{restaurant.street}</div>

          <h3 className={typography.h6}>City</h3>
          <div className={typography.c2}>{restaurant.city}</div>

          <h3 className={typography.h6}>State</h3>
          <div className={typography.c2}>{restaurant.state}</div>

          <h3 className={typography.h6}>Zip Code</h3>
          <div className={typography.c2}>{restaurant.postalCode}</div>
        </RestaurantSettingsSection>

        <ScheduleSettings
          schedule={restaurant.schedule}
          refreshRestaurant={refreshRestaurant}
          restaurantId={restaurant.id}
        />

        <MaxGuestCountSettings
          maxGuestCount={restaurant.maxReservationGuests}
          phone={formatPhone(restaurant.phone)}
          refreshRestaurant={refreshRestaurant}
          restaurantId={restaurant.id}
        />

        <CancellationPolicySettings
          cancellationPolicy={restaurant.cancellationPolicy}
          refreshRestaurant={refreshRestaurant}
          restaurantId={restaurant.id}
        />

        <CoverLimitSettings
          coverLimit={restaurant.coverLimit}
          refreshRestaurant={refreshRestaurant}
          restaurantId={restaurant.id}
        />
      </PageContent>
    </>
  );
};
