// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout__pageMargin___N0bV8{margin:0 auto;max-width:var(--pageContentMaxWidth);padding:0 1.5rem;width:100%}@media only screen and (min-width: 834px){.Layout__pageMargin___N0bV8{padding:0 2.5rem}}.Layout__main___rrIRQ{flex:1 0 0;display:flex;flex-direction:column}.Layout__pageHeader___K6zmC{background-color:var(--primary500);padding:1.75rem 0}.Layout__pageHeader___K6zmC>div{align-items:center;display:flex}.Layout__pageHeader___K6zmC>div>h1{flex:1}.Layout__pageHeader___K6zmC .Layout__category___O8qXQ{color:var(--white70);letter-spacing:.0625em;text-transform:uppercase}.Layout__pageHeader___K6zmC .Layout__actions___ls7qf{align-items:center;display:flex;flex-direction:row;gap:1rem}.Layout__pageContent___Z0Ggs{flex:1 0 0;margin-top:1.5rem;margin-bottom:1.5rem}.Layout__pageWrapper___x8Ze2{display:flex;flex:1;flex-direction:column}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/layout/Layout.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,4BACE,aAAA,CACA,oCAAA,CACA,gBAAA,CACA,UAAA,CC0BA,0CD9BF,4BAOI,gBAAA,CAAA,CAIJ,sBACE,UAAA,CACA,YAAA,CACA,qBAAA,CAGF,4BACE,kCAAA,CACA,iBAAA,CAEA,gCACE,kBAAA,CACA,YAAA,CAEA,mCACE,MAAA,CAIJ,sDACE,oBAAA,CACA,sBAAA,CACA,wBAAA,CAGF,qDACE,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,QAAA,CAIJ,6BACE,UAAA,CACA,iBAAA,CACA,oBAAA,CAIF,6BACE,YAAA,CACA,MAAA,CACA,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageMargin": "Layout__pageMargin___N0bV8",
	"main": "Layout__main___rrIRQ",
	"pageHeader": "Layout__pageHeader___K6zmC",
	"category": "Layout__category___O8qXQ",
	"actions": "Layout__actions___ls7qf",
	"pageContent": "Layout__pageContent___Z0Ggs",
	"pageWrapper": "Layout__pageWrapper___x8Ze2"
};
export default ___CSS_LOADER_EXPORT___;
